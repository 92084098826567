import Link from './url'
// Credit: https://raw.githubusercontent.com/trevordmiller/social-media-links/master/index.js

export function hasher() {
  return Math.floor((1 + Math.random()) * 0x10000)
    .toString(16)
    .substring(1);
}

function fullPath() {
  return window.location.origin + window.location.pathname
}

export function create({account = 'twitter', url = fullPath(), title, via = '_ZPH', hashtags = ['']} = {}) {
  const commaHashtags = !hashtags[0] ? '' : hashtags.join(',')
  const hash = hasher()
  const ut = new Link(url)
  ut.setQueryParam("i", hash)
  const urlWithTag = encodeURIComponent(ut.toString())
  var v = encodeURIComponent(via)
  var t = encodeURIComponent(title)

  switch (account) {
  case 'email':
    return `mailto:?subject=${title}&body=${url}`;
  case 'twitter':
    return `https://twitter.com/share?url=${urlWithTag}&text=${t}&via=${v}&hashtags=${commaHashtags}`;
  }
}
