import * as SocialMediaLinks from './social-media-links';

document.addEventListener('DOMContentLoaded', function() {
  document.querySelectorAll('.twitter-share').forEach((el) => {
    const {via, title, aliases} = el.dataset;
    var ax, url;
    try {
      ax = JSON.parse(aliases);
      url = ax[0];
    } catch (e) {
      url = el.dataset.url;
    }
    const href = SocialMediaLinks.create({
      account: 'twitter',
      url: url,
      title: title,
      via: via,
    });
    el.href = href;
  });
  // if (process.env.NODE_ENV !== 'production') {
  //   console.log('Page Metadata %o', window.pageMetadata);
  // }
});
